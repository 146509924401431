import { render, staticRenderFns } from "./AnswerTile.vue?vue&type=template&id=f21c4acc&scoped=true"
import script from "./AnswerTile.vue?vue&type=script&lang=js"
export * from "./AnswerTile.vue?vue&type=script&lang=js"
import style0 from "./AnswerTile.vue?vue&type=style&index=0&id=f21c4acc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f21c4acc",
  null
  
)

export default component.exports