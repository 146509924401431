<template>
  <div class="material-tile" @click="editActivity">
    <div class="material-tile__left">
      <div class="material-tile__drag">
        <DragDots />
      </div>
      <div class="material-tile__icon">
        <component :is="iconComponent" />
      </div>
      <div
        class="material-tile__title"
        @click.stop.prevent="downLoadFile(item)"
        v-if="item.type === 'material'"
      >
        {{ fileName }}
      </div>
      <div class="material-tile__title" v-else>
        {{ fileName }}
      </div>
    </div>
    <div class="material-tile__right">
      <div class="material-tile__size">
        <span v-if="fileSize">({{ fileSize }})</span><span v-else></span>
      </div>
      <div class="material-tile__extension">{{ fileExtension }}</div>
      <div class="material-tile__dropdown">
        <b-dropdown size="sm" dropleft variant="link" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <BigOptionsButton />
          </template>
          <div class="dropdown__wrapper">
            <b-dropdown-item
              v-if="item.type === 'task'"
              href="#"
              @click.stop="
                $router.push({
                  name: 'CheckHomeworksPage',
                  query: { taskId: item._id },
                  params: { path: $route.fullPath },
                })
              "
              >{{ $t('buttonLabels.check') }}
            </b-dropdown-item>
            <b-dropdown-item href="#" @click.stop="openStatistic(item.type, item._id, item.name)"
              >{{ $t('buttonLabels.statistics') }}
            </b-dropdown-item>
            <b-dropdown-item href="#" @click.stop="showModalRemove(item._id, item.type)"
              >{{ $t('buttonLabels.delete') }}
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import urlParser from 'js-video-url-parser';
import constantsMixin from '@/mixins/constants.mixin';
import BigOptionsButton from '@/components/Buttons/BigOptionsButton.vue';
import DragDots from '@/components/Icons/DragDots.vue';
import PptxIcon from '@/components/Icons/PptxIcon.vue';
import PdfIcon from '@/components/Icons/PdfIcon.vue';
import DocxIcon from '@/components/Icons/DocxIcon.vue';
import QuizIcon from '@/components/Icons/QuizIcon.vue';
import VideoIcon from '@/components/Icons/VideoIcon.vue';

export default {
  name: 'MaterialTile',
  props: {
    item: {
      type: [Object, String],
      required: true,
    },
    routerPush: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    BigOptionsButton,
    DragDots,
  },
  mixins: [constantsMixin],
  inject: {
    openStatistic: {
      default: () => ({}),
    },
  },
  computed: {
    parsedLink() {
      return urlParser.parse(this.item.meta?.url);
    },
    videoType() {
      if (this.item.meta?.url.includes('rutube')) {
        return 'Rutube';
      }
      if (this.parsedLink?.provider === 'youtube') {
        return 'YouTube';
      }
      if (this.parsedLink?.provider === 'vimeo') {
        return 'Vimeo';
      }
      return '';
    },
    fileName() {
      const { name } = this.item;
      return name;
    },
    fileExtension() {
      if (this.item.meta?.fileExtension) {
        return `.${this.item.meta.fileExtension}`;
      }
      if (this.item.type === 'video') return this.videoType;
      return '';
    },
    fileSize() {
      if (this.item.meta?.fileSize) {
        return this.item.meta.fileSize;
      }
      return '';
    },
    iconComponent() {
      if (this.item.type === 'quiz') return QuizIcon;
      if (this.item.type === 'video') return VideoIcon;
      if (this.item.type === 'task') return QuizIcon;
      if (this.item.meta?.fileExtension === this.pptx) return PptxIcon;
      if (this.item.meta?.fileExtension === this.pdf) return PdfIcon;
      if (this.item.meta?.fileExtension === this.docx) return DocxIcon;
      return QuizIcon;
    },
  },
  methods: {
    ...mapActions({
      remove: 'courseContentTeacher/removeActivity',
      selectContentId: 'courseContentTeacher/selectContentId',
      openModal: 'modalType/setCurrentModalType',
    }),
    downLoadFile(item) {
      window.open(`https://${item.meta.url}`);
    },
    editActivity() {
      this.selectContentId(this.item._id);
      this.openModal(this.item.type === 'material' ? 'edit-material' : this.item.type);
    },
    showModalRemove(id, type) {
      let text;
      if (type === 'task') {
        text = this.$t('supportText.actionCannotBeUndoneTask');
      }
      if (type === 'material') {
        text = this.$t('supportText.actionCannotBeUndoneMaterial');
      }
      if (type === 'quiz') {
        text = this.$t('supportText.actionCannotBeUndoneQuiz');
      }
      if (type === 'video') {
        text = this.$t('supportText.actionCannotBeUndoneVideo');
      }
      this.$bvModal
        .msgBoxConfirm(text, {
          size: 'sm',
          okVariant: 'secondary',
          cancelVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'lms-modal__footer border-top-0',
          centered: true,
          bodyClass: 'lms-modal__body',
          okTitle: this.$t('buttonLabels.delete'),
          cancelTitle: this.$t('buttonLabels.cancel'),
        })
        .then((isConfirmed) => ({
          isConfirmed,
          id,
        }))
        .then(this.checkModalConfirm)
        .catch(console.log);
    },
    checkModalConfirm({ isConfirmed, id }) {
      if (!isConfirmed) {
        return;
      }
      this.remove(id);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep button.dropdown-toggle:focus {
  box-shadow: none;
}

.material-tile__dropdown ::v-deep .dropdown-item:hover {
  background: #273344;
  color: #f2f2f2;
}
</style>
